import { EnvironmentEnum } from '@ct/shared/domain';
import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
  type: EnvironmentEnum.STAGING,
  apiUri: '/api/v1',
  betaVersion: true,
  apiUrl: 'https://test-api.cointips.eu',
  appUrl: 'https://test.cointips.eu',
  authProviders: {
    google: '/auth/google',
  },
  walletConnect: {
    projectId: '5e2fb270c5d88bc91b5afcd65ad4fce7',
  },
  contracts: {
    11_155_111: {
      orchestrator: '0xbaBd7490E1a5b162348f843FbdA88e6D9ACbc3AA',
      nft: '0x864d93d979F40319D2b9c6135d2d80fa63de5690',
    },
  },
  analytics: {
    key: 'GTM-N8DFGR27',
    url: 'https://www.googletagmanager.com/gtm.js?id=GTM-N8DFGR27',
  }
};
