<!-- Start Main Banner Area -->
<div class="main-banner">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="main-banner-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="content">
                                <h1>Receive Crypto Tips <span>Now</span> And Connect with your <span>Community.</span></h1>
                                <p>Join the growing number of streamers earning more with our crypto tips solution.</p>
                                <a *ngIf="this.authService.isLoggedIn" routerLink="/user/profile" class="default-btn"><i class="bx bxs-hot"></i>Get started<span></span></a>
                                <a *ngIf="!this.authService.isLoggedIn" routerLink="/auth/signup" class="default-btn"><i class="bx bxs-hot"></i>Get started<span></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="banner-image mbanner-bg-one">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="animate-banner-image">
                                <img src="assets/img/animate-banner-img1.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape20"><img src="assets/img/shape/19.png" alt="image"></div>
    <div class="shape21"><img src="assets/img/shape/20.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/18.png" alt="image"></div>
    <div class="shape22"><img src="assets/img/shape/21.png" alt="image"></div>
    <div class="shape23"><img src="assets/img/shape/22.svg" alt="image"></div>
    <div class="shape24"><img src="assets/img/shape/23.png" alt="image"></div>
    <div class="shape26"><img src="assets/img/shape/25.png" alt="image"></div>
</div>
<!-- End Main Banner Area -->

<!-- <app-partner></app-partner> -->

<!-- Start Services Area -->
<section class="services-area bg-left-color bg-f4f6fc ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-image wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                <div class="image">
                    <img src="assets/img/services-image/page.png" alt="image">
                </div>
            </div>

            <div class="services-content">
                <div class="content">
                    <div class="icon">
                        <img src="assets/img/icon1.png" alt="image">
                    </div>
                    <h2><span>Create</span> Your Own Page Today</h2>
                    <p>Ready to turn your passion into profit? Create your personalized donation page in minutes and start accepting cryptocurrency tips from your loyal viewers. It's simple, seamless, and secure. Let your audience show their support the crypto way!"</p>
                    <a *ngIf="this.authService.isLoggedIn" routerLink="/user/profile" class="default-btn"><i class="bx bxs-spreadsheet"></i> Create my Page<span></span></a>
                    <a *ngIf="!this.authService.isLoggedIn" routerLink="/auth/signup" class="default-btn"><i class="bx bxs-spreadsheet"></i> Create my Page<span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Services Area -->
<section class="services-area bg-right-color ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-content">
                <div class="content left-content">
                    <div class="icon">
                        <img src="assets/img/icon1.png" alt="image">
                    </div>
                    <h2>Turn Your Viewers Into a <span>Real Community</span></h2>
                    <p>Quickly integrate Cointips with OBS Xsplit or Streamlabs to show off custom alert on your live!</p>
                    <a *ngIf="this.authService.isLoggedIn" routerLink="/user/profile" class="default-btn"><i class="bx bxs-spreadsheet"></i> Create now<span></span></a>
                    <a *ngIf="!this.authService.isLoggedIn" routerLink="/auth/signup" class="default-btn"><i class="bx bxs-spreadsheet"></i> Create now<span></span></a>
                </div>
            </div>

            <div  class="services-image wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
                <div class="image">
                    <img src="assets/img/services-image/CointipsCreateCommunity.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Services Area -->
<section class="services-area bg-left-color bg-f4f6fc ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-image wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                <div class="image">
                    <img src="assets/img/services-image/reward.png" alt="image">
                </div>
            </div>

            <div class="services-content">
                <div class="content">
                    <div class="icon">
                        <img src="assets/img/icon1.png" alt="image">
                    </div>
                    <h2><span>Thanks</span> your viewers with Digital Community Passes</h2>
                    <p>Tip and Treasure! When your viewers send you cryptocurrency tips through Cointips, they aren't just supporting your stream—they're earning unique Digital Community Passes. These non-fungible tokens unlock a world of possibilities.</p>
                    <a routerLink="/how-it-works" class="default-btn"><i class="bx bxs-spreadsheet"></i> Learn More<span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->



<!-- Start Services Area -->
<section class="services-area bg-right-color ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-content">
                <div class="content left-content">
                    <div class="icon">
                        <img src="assets/img/icon1.png" alt="image">
                    </div>
                    <h2>Integrate <span>Tips Alerts</span>  On Your Stream</h2>
                    <p>Quickly integrate Cointips with OBS Xsplit or Streamlabs to show off custom alert on your live!</p>
                    <a *ngIf="this.authService.isLoggedIn" routerLink="/user/profile" class="default-btn"><i class="bx bxs-spreadsheet"></i> Create now<span></span></a>
                    <a *ngIf="!this.authService.isLoggedIn" routerLink="/auth/signup" class="default-btn"><i class="bx bxs-spreadsheet"></i> Create now<span></span></a>
                </div>
            </div>

            <div  class="services-image wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
                <div class="image">
                    <img src="assets/img/services-image/integration.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->


<!-- Start Video Presentation Area -->
<section class="video-presentation-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Discover <span>CoinTips</span> In Action
            </h2>
        </div>

        <div class="video-box">
            <img src="assets/img/video-bg.jpg" class="main-image" alt="image">
            <a href="https://www.youtube.com/watch?v=0gv7OC9L2s8" class="video-btn popup-youtube"><i class="bx bx-play"></i></a>
            <div class="shape1"><img src="assets/img/shape/1.png" alt="image"></div>
            <div class="shape2"><img src="assets/img/shape/2.png" alt="image"></div>
            <div class="shape3"><img src="assets/img/shape/3.png" alt="image"></div>
            <div class="shape4"><img src="assets/img/shape/4.png" alt="image"></div>
            <div class="shape5"><img src="assets/img/shape/5.png" alt="image"></div>
            <div class="shape6"><img src="assets/img/shape/6.png" alt="image"></div>
        </div>

        <div class="contact-cta-box">
            <h3>Have any question about us?</h3>
            <p>Don't hesitate to contact us.</p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-edit-alt"></i>Contact Us<span></span></a>
        </div>
    </div>

    <div class="shape-map1"><img src="assets/img/map1.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/8.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape/9.png" alt="image"></div>
</section>
<!-- End Video Presentation Area -->

<!-- Start Features Area -->
<section class="features-area pt-100 pb-70 bg-f4f6fc">
    <div class="container">
        <div class="section-title">
            <h2>Our Amazing  <span>Features</span>
            </h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-bitcoin'></i>
                    </div>
                    <h3>Accept a Variety of Cryptos</h3>
                    <p>CoinTips offers multiwallet integration, allowing you to accept a wide range of cryptocurrencies effortlessly.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-bell'></i>
                    </div>
                    <h3>On-Stream Alerts</h3>
                    <p>Engage your audience like never before with our live alert feature. When a supporter makes a donation, you and your viewers will instantly receive notifications on your stream. </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-slider-alt'></i>
                    </div>
                    <h3>Quick Setup</h3>
                    <p>CoinTips is designed for simplicity. Our intuitive interface and user-friendly tools make it a breeze to set up your personalized donation page.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Features Area -->

<!-- Start Feedback Area -->
<section class="feedback-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Whats Our Creators Said About <span>CoinTips</span></h2>
        </div>

        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-item">
                <img src="assets/img/feedback/feedback1.png" alt="image">

                <div class="feedback-desc">
                    <p>The platform is user-friendly, and the variety of cryptocurrencies accepted is a big plus. It's a win-win for creators and supporters alike.</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Josh Taylor</h3>
                        <span>Actif Viewer</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-item">
                <img src="assets/img/feedback/feedback2.png" alt="image">

                <div class="feedback-desc">
                    <p>"As a content creator, CoinTips has been a game-changer for me. It's helped me diversify my income streams and build a loyal community around my content.</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Jake Lucy</h3>
                        <span>Content Creator</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-item">
                <img src="assets/img/feedback/feedback3.png" alt="image">

                <div class="feedback-desc">
                    <p>Not only can I accept crypto donations effortlessly, but the analytics and insights have helped me tailor my content to what my viewers prefer</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Ellie Smith</h3>
                        <span>Creator</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Start Feedback Area -->

<!-- <app-pricing-plan></app-pricing-plan> -->

<!-- Start FAQ Area -->
<section class="faq-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <h2>Get to know about <span>CoinTips</span></h2>

                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                What is CoinTips?
                            </a>
                            <p class="accordion-content">
                                CoinTips is a platform that allows content creators, such as streamers, to receive cryptocurrency donations from their supporters. It simplifies the process of monetizing your content and provides you with the tools to engage with your audience in a crypto-friendly way.
                            </p>
                        </li>


                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                Which cryptocurrencies are supported on CoinTips?
                            </a>
                            <p class="accordion-content">
                                CoinTips supports a variety of cryptocurrencies, including but not limited to Bitcoin (BTC), Ethereum (ETH), Stablecoin (USDC), and more. <!-- You can choose which cryptocurrencies you'd like to accept on your donation page. -->
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                Is CoinTips secure?
                            </a>
                            <p class="accordion-content">
                                Yes, security is a top priority for us. We employ industry-standard encryption and security measures to protect your account and transactions. Your crypto donations are safe with us.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                Are there any fees for using CoinTips?
                            </a>
                            <p class="accordion-content">
                                CoinTips offers both free and premium plans. Our free plan allows you to get started without any upfront costs, while our premium plans offer additional features and customization options. Check out our Pricing page for more details.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End FAQ Area -->

<!-- <app-clients></app-clients> -->
<app-partner></app-partner>
<!-- <app-free-trial></app-free-trial> -->
