import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CustomEncoder, handleApiError } from '@ct/client/util';
import { ICreateTip, IMedia, ITip, IReward, IUpdateTip, PaginatedResource, ITipRequest, ITipResponse } from '@ct/shared/domain';
import { environment } from '@ct/shared/util-env';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TipService {
  private readonly http = inject(HttpClient);
  private readonly baseUrl = `${environment.apiUri}/tips`;

  getTips(filter?: string, sort?: string): Observable<PaginatedResource<ITip>> {
    let params = new HttpParams({
      encoder: new CustomEncoder()
    });
    if(filter) {
      params = params.set('filter', filter);
    }
    if (sort) {
      params = params.set('sort', sort);
    }

    return this.http
      .get<PaginatedResource<ITip>>(`${this.baseUrl}`, { params })
      .pipe(handleApiError);
  }

  getTip(id: string): Observable<ITip> {
    return this.http
      .get<ITip>(`${this.baseUrl}/${id}`)
      .pipe(handleApiError);
  }

  updateTip(id: string, data: IUpdateTip): Observable<ITip> {
    return this.http
      .put<ITip>(`${this.baseUrl}/${id}`, data)
      .pipe(handleApiError);
  }

  updateOnChainDetails(id: string | null): Observable<ITip> {
    const url = id ? `${this.baseUrl}/${id}/onchain` : `${this.baseUrl}/onchain`;
    return this.http
      .put<ITip>(url, {})
      .pipe(handleApiError);
  }

  createTip(data: ICreateTip): Observable<ITip> {
    return this.http
      .post<ITip>(this.baseUrl, data)
      .pipe(handleApiError);
  }

  requestTip(data: ITipRequest): Observable<ITipResponse> {
    return this.http
      .post<ITipResponse>(`${this.baseUrl}/request`, data)
      .pipe(handleApiError);
  }
}
