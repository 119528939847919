<!-- Start Signup Area -->
<section class="signup-area">
  <div class="row m-0">
    <div class="col-lg-6 col-md-12 p-0">
      <div class="signup-image">
        <img src="assets/img/signup-bg.jpg" alt="image" />
      </div>
    </div>

    <div class="col-lg-6 col-md-12 p-0">
      <div class="signup-content pt-70">
        <div class="d-table">
          <div class="d-table-cell">
            <div class="signup-form">
              <div class="logo d-none d-sm-block">
                
                  <img src="assets/img/logo.png" alt="image" />
                
              </div>
              <h3>Open up your CoinTips Account now</h3>
              <p>Already signed up? <a routerLink="/auth/signin">Log in</a></p>
              <form [formGroup]="registerForm">
                <div class="form-group mb-3">
                  <input type="email" autocomplete="email" name="email" formControlName="email" id="email" placeholder="Your email address" class="form-control" [class.is-invalid]="fEmail.invalid && (fEmail.touched || fEmail.dirty)" />
                  <div class="invalid-feedback" *ngIf="fEmail.errors">
                    <ng-container *ngIf="fEmail.hasError('required')"> The email is required </ng-container>
                    <ng-container *ngIf="fEmail.hasError('email')"> Email must be a valid email address </ng-container>
                  </div>
                </div>
                <div class="form-group mb-3">
                  <input
                    type="password"
                    autocomplete="new-password"
                    name="password"
                    formControlName="password"
                    id="password"
                    placeholder="Create a password"
                    class="form-control"
                    [class.is-invalid]="fPassword.invalid && (fPassword.touched || fPassword.dirty)" />
                  <div class="invalid-feedback" *ngIf="fPassword.errors">
                    <ng-container *ngIf="fPassword.hasError('required')"> The password is required </ng-container>
                    <ng-container *ngIf="fPassword.hasError('minlength')"> The password must have a minimum of 8 characters </ng-container>
                  </div>
                </div>
                <div class="form-group mb-3">
                  <input
                    type="password"
                    name="confirmedPassword"
                    formControlName="confirmedPassword"
                    id="confirmedPassword"
                    placeholder="Confirm your password"
                    class="form-control"
                    [class.is-invalid]="(fConfirmedPassword.invalid || registerForm.hasError(matchingErrorKey)) && (fConfirmedPassword.touched || fConfirmedPassword.dirty)" />
                  <div class="invalid-feedback" *ngIf="fConfirmedPassword.errors || registerForm.hasError(matchingErrorKey)">
                    <ng-container *ngIf="fConfirmedPassword.hasError('required')"> The password confirmation is required </ng-container>
                    <ng-container *ngIf="registerForm.hasError(matchingErrorKey)"> Passwords do not match! </ng-container>
                  </div>
                </div>
                <div class="form-group form-check col-12 mb-3">
                  <input type="checkbox" class="form-check-input" formControlName="terms" name="terms" id="terms" />
                  <label class="form-check-label" for="terms"
                    >I have read and agreed with the <a target="_blank" routerLink="/terms-conditions">Terms & Conditions</a> and the <a target="_blank" routerLink="/privacy-policies">Privacy Policy</a>.</label
                  >
                </div>
                <div class="form-group form-check col-12 mb-3">
                  <input type="checkbox" class="form-check-input" formControlName="marketing" name="marketing" id="marketing" />
                  <label class="form-check-label" for="marketing">I accept to receive personalised commercial communications from <strong>CoinTips</strong> by email.</label>
                </div>
                <p class="text-danger" *ngIf="errorMessage$ | async as err">{{ err }}</p>
                <button type="submit" class="default-btn" [disabled]="signupPending" [class.btn-disabled]="registerForm.invalid" (click)="register()">
                  <i *ngIf="!signupPending" class="bx bx-log-in"></i>
                  <i *ngIf="signupPending" class="bx bx-loader-circle rotateme"></i>
                  Sign Up<span></span>
                </button>
                <p class="separator">or</p>
                <div class="connect-with-social">
                  <button type="submit" class="google" (click)="loginWithGoogle()"><i class="bx bxl-google"></i> Connect with Google</button>
                  <button type="submit" class="metamask" (click)="loginWithWeb3()"><i class="bx bx-wallet"></i> Connect with Wallet</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Login Area -->
