<!-- Start Blog Area -->
<section class="blog-area pt-100">
  <div class="container">
    <ng-container *ngIf="{ page: page$ | async, error: pageError$ | async } as data">
      <ng-container *ngIf="data.page; else loading">
        <div class="row pb-100">
          <div class="col-md-4">
            <aside class="widget-area pt-20" id="secondary">
              <section
                class="widget"
                *ngIf="{
                  page: page$ | async,
                } as data">
                <div class="col-md-12">
                  <div class="card card-user">
                    <div class="image">
                      <img [alt]="data.page?.bannerImage?.alt ? data.page?.bannerImage?.alt : data.page?.title" [src]="data.page?.bannerImage?.path" appImgFallback="assets/img/default-banner.png" />
                    </div>
                    <div class="card-body">
                      <div class="author">
                        <img
                          class="avatar rounded-circle border-gray"
                          [alt]="data.page?.profileImage?.alt ? data.page?.profileImage?.alt : data.page?.title"
                          [src]="data.page?.profileImage?.path"
                          appImgFallback="assets/img/default-thumbnail.png" />
                        <h5 class="title">{{ data.page?.title }}</h5>

                        <p class="description" *ngIf="data.page?.slug">
                          <a target="_blank" [routerLink]="['/pages', data.page?.slug]">&commat;{{ data.page?.slug }}</a>
                          <i class="bx bxs-badge-check" *ngIf="data.page?.certified"></i>
                        </p>
                      </div>
                      <p class="description text-center">
                        {{ data.page?.content }}
                      </p>
                    </div>

                    <div>
                      <ng-container *ngIf="data.page && data.page.medias">
                        <div class="contact-info">
                          <div class="contact-info-content">
                            <ul class="social">
                              <ng-template ngFor let-media [ngForOf]="data.page.medias">
                                <li>
                                  <a target="_blank" [href]="'http://' + media.value"><i class="bx" [ngClass]="mediaClasses[media.type].iconClass"></i></a>
                                </li>
                              </ng-template>
                            </ul>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <!-- <div class="card-footer">
                  <hr>
                  <div class="button-container">
                    <div class="row">
                      <div class="col-lg-3 col-md-6 col-6 ml-auto">
                        <h5>1M
                          <small>Viewers</small>
                        </h5>
                      </div>
                      <div class="col-lg-4 col-md-6 col-6 ml-auto mr-auto">
                        <h5>300k
                          <br>
                          <small>Subs</small>
                        </h5>
                      </div>
                      <div class="col-lg-3 mr-auto">
                        <h5>350M
                          <br>
                          <small>Total donations</small>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div> -->
                  </div>
                </div>
              </section>

              <section class="widget pt-20 d-none d-sm-none d-md-block" *ngIf="page && page.status === 'published'">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title">Donators</h4>
                  </div>
                  <div class="card-body">
                    <ng-container *ngIf="tips$ | async as tips; else loadingDonators">
                      <ng-container *ngIf="tips && tips.length > 0; else emptyList">
                        <ul class="list-unstyled team-members">
                          <li *ngFor="let tip of tips">
                            <div class="row">
                              <div class="col-md-2 col-2">
                                <div class="avatar">
                                  <img [alt]="tip.user?.profileImage?.alt ? tip.user?.profileImage?.alt : tip.user?.pseudo" [src]="tip.user?.profileImage?.path" appImgFallback="assets/img/default-profile.jpg" />
                                </div>
                              </div>
                              <div class="col-md-10 col-10">
                                {{ tip.user?.pseudo ? tip.user?.pseudo : 'Anonymous' }}
                                <br />
                                <span class="text-right float-end">
                                  <small>~{{ tip.amountUSD | currency: 'USD' }}</small>
                                </span>
                                <span class="text-info">
                                  <small>{{ tip.executedAt | date: 'mediumDate' }}</small>
                                </span>
                                <!-- TODO require complex request to get the nft image, not sure it's mandatory
                              <br />
                            <span class="text-success">
                              <small>Unlock </small>
                              <img
                                src="assets/img/services-image/03eth.jpg"
                                alt="Circle Image"
                                class="tp-eth-reward"
                              />
                            </span> -->
                              </div>
                            </div>
                          </li>
                        </ul>
                      </ng-container>
                      <ng-template #emptyList>
                        <p class="text-center pt-20">No Donation yet</p>
                      </ng-template>
                    </ng-container>
                    <ng-template #loadingDonators> Loading donators... </ng-template>
                  </div>
                </div>
              </section>
            </aside>
          </div>

          <div class="col-md-8">
            <section class="tp-details pt-20">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">
                    Tip Details
                    <button *ngIf="canEdit" class="default-btn float-end" [routerLink]="['/pages', data.page.id, 'edit']">
                      <i class="bx bxs-edit"></i>
                      Edit your page
                      <span></span>
                    </button>
                  </h4>
                </div>
                <div class="card-body">
                  <app-tipping-content *ngIf="page && page.status === 'published'; else notPublished" [page]="page" [includedReward]="includedReward" (tipChanged)="tipChanged($event)"></app-tipping-content>
                  <ng-template #notPublished>
                    <p class="text-center pt-20">This page is not yet published. Come back later !</p>
                  </ng-template>
                </div>
              </div>
            </section>
            <section class="tp-rewards" *ngIf="rewards$ | async as rewards">
              <div class="card" *ngIf="rewards && rewards.length > 0">
                <div class="card-header">
                  <h4 class="card-title">Available Digital Community Passes</h4>
                </div>
                <div class="card-body">
                  <app-rewards [rewards]="rewards!" [includedRewardId]="includedRewardId" (rewardSelected)="rewardSelected($event)"></app-rewards>
                </div>
              </div>
            </section>
          </div>
          <section class="widget d-block d-md-none pt-20" *ngIf="page && page.status === 'published'">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Donators</h4>
              </div>
              <div class="card-body">
                <ng-container *ngIf="tips$ | async as tips; else loadingDonators">
                  <ng-container *ngIf="tips && tips.length > 0; else emptyList">
                    <ul class="list-unstyled team-members">
                      <li *ngFor="let tip of tips">
                        <div class="row">
                          <div class="col-md-2 col-2">
                            <div class="avatar">
                              <img [alt]="tip.user?.profileImage?.alt ? tip.user?.profileImage?.alt : tip.user?.pseudo" [src]="tip.user?.profileImage?.path" appImgFallback="assets/img/default-profile.jpg" />
                            </div>
                          </div>
                          <div class="col-md-10 col-10">
                            {{ tip.user?.pseudo }}
                            <br />
                            <span class="text-right float-end">
                              <small>~{{ tip.amountUSD | currency: 'USD' }}</small>
                            </span>
                            <span class="text-info">
                              <small>{{ tip.executedAt | date: 'mediumDate' }}</small>
                            </span>
                            <!-- TODO require complex request to get the nft image, not sure it's mandatory
                          <br />
                        <span class="text-success">
                          <small>Unlock </small>
                          <img
                            src="assets/img/services-image/03eth.jpg"
                            alt="Circle Image"
                            class="tp-eth-reward"
                          />
                        </span> -->
                          </div>
                        </div>
                      </li>
                    </ul>
                  </ng-container>
                  <ng-template #emptyList>
                    <p class="text-center pt-20">No Donation yet</p>
                  </ng-template>
                </ng-container>
                <ng-template #loadingDonators> Loading donators... </ng-template>
              </div>
            </div>
          </section>
        </div>
      </ng-container>
      <ng-template #loading>
        <div *ngIf="!data.error">
          <ct-preloader [inner]="true"></ct-preloader>
        </div>
        <div *ngIf="data.error">
          <app-error></app-error>
        </div>
      </ng-template>
    </ng-container>
  </div>
</section>
<!-- End Blog Area -->
