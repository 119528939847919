import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { NgbTooltipModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layout/preloader/preloader.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { HomeComponent } from './components/pages/home/home.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { PricingPlanComponent } from './components/common/pricing-plan/pricing-plan.component';
import { ClientsComponent } from './components/common/clients/clients.component';
import { FreeTrialComponent } from './components/common/free-trial/free-trial.component';
import { TippingContentComponent } from './components/common/tipping-content/tipping-content.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { AboutComponent } from './components/pages/about/about.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { TeamComponent } from './components/pages/team/team.component';
import { FeaturesComponent } from './components/pages/features/features/features.component';
import { FeaturesOneComponent } from './components/pages/features/features-one/features-one.component';
import { FeaturesTwoComponent } from './components/pages/features/features-two/features-two.component';
import { AuthComponent } from './components/pages/auth/auth/auth.component';
import { SigninComponent } from './components/pages/auth/signin/signin.component';
import { SignupComponent } from './components/pages/auth/signup/signup.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { BlogComponent } from './components/pages/blog/blog/blog.component';
import { BlogGridComponent } from './components/pages/blog/blog-grid/blog-grid.component';

import { BlogSidebarComponent } from './components/pages/blog/blog-sidebar/blog-sidebar.component';
import { BlogRewardsComponent } from './components/pages/blog/blog-rewards/blog-rewards.component';
import { BlogRevolutionComponent } from './components/pages/blog/blog-revolution/blog-revolution.component';
import { BlogOverlayObsComponent } from './components/pages/blog/blog-overlay/obs/blog-overlay-obs.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { HomeFiveComponent } from './components/pages/home-five/home-five.component';
import { HomeSixComponent } from './components/pages/home-six/home-six.component';
import { HomeSevenComponent } from './components/pages/home-seven/home-seven.component';
import { HomeEightComponent } from './components/pages/home-eight/home-eight.component';
import { ServicesOneComponent } from './components/pages/services-one/services-one.component';
import { ServicesTwoComponent } from './components/pages/services-two/services-two.component';
import { ServicesThreeComponent } from './components/pages/services-three/services-three.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { HomeNineComponent } from './components/pages/home-nine/home-nine.component';
import { HomeTenComponent } from './components/pages/home-ten/home-ten.component';
import { HomeElevenComponent } from './components/pages/home-eleven/home-eleven.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { SizeDetectorComponent, TransactionToastComponent } from '@ct/client/ui-components';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { ProviderSigninComponent } from './components/pages/auth/provider/signin/provider-signin.component';
import { TippingPageComponent } from './components/pages/tipping-page/tipping-page.component';
import { TotpEnableComponent } from './components/pages/tipping-page/edit/totp/enable/totp-enable.component';
import { TotpVerifyComponent } from './components/pages/tipping-page/edit/totp/verify/totp-verify.component';
import { EditTippingPageComponent } from './components/pages/tipping-page/edit/edit-tipping-page.component';
import { WidgetTippingPageComponent } from './components/pages/widgets/tipping-page/widget-tipping-page.component';
import { HeaderOldComponent } from './components/layout/header-old/header-old.component';
import { AuthService, AuthInterceptor, LatencyInterceptor } from '@ct/client/data-access';
import { UserProfileComponent } from './components/pages/user/profile/user-profile.component';
import { ProviderCallbackComponent } from './components/pages/auth/provider/callback/provider-callback.component';
import { WidgetsComponent } from './components/pages/widgets/widgets/widgets.component';
import { OverlaysComponent } from './components/pages/overlays/overlays/overlays.component';
import { FormatAmountPipe, ImgFallbackDirective } from '@ct/client/util';
import { ForgotPasswordComponent } from './components/pages/auth/forgot-password/forgot-password.component';
import { AgGridModule } from 'ag-grid-angular';
import { HeaderMinimalComponent } from './components/layout/header-minimal/header-minimal.component';
import { NgxConfettiModule } from "angular-confetti";
import { ToastNoAnimationModule } from 'ngx-toastr';
import { catchError, map, of } from 'rxjs';
import { RewardsComponent } from './components/common';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NotificationsOverlayComponent } from './components/pages/overlays/notifications/notifications-overlay.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { QrCodeModule } from 'ng-qrcode';
import { WalletInputComponent } from './components/common/wallet-input/wallet-input.component';
import { ResetPasswordComponent } from './components/pages/auth/reset-password/reset-password.component';
import { ClipboardModule } from 'ngx-clipboard';
import { HowItWorksComponent } from './components/pages/how-it-works/how-it-works.component';
import { BlogTokenGatingComponent } from './components/pages/blog/blog-token-gating/blog-token-gating.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { AdminMailComponent } from './components/pages/admin/mail/mail.component';
import { AdminSupportComponent } from './components/pages/admin/support/support.component';
import { NgxGoogleAnalyticsModule, provideGoogleAnalytics, provideGoogleAnalyticsRouter } from '@hakimio/ngx-google-analytics';
import { NgOtpInputModule } from 'ng-otp-input';
import { environment } from '@ct/shared/util-env';
import { SeoService, UrlService } from './shared';

const domain = (new URL(environment.appUrl));

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: domain.hostname // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'edgeless',
  type: 'info'
};

const imports: any[] = [];
const providers: any[] = [];
if (environment.analytics) {
  console.log('Using analytics', environment.analytics)
  imports.push(NgxGoogleAnalyticsModule);
  providers.push(provideGoogleAnalytics(environment.analytics.key, { ga4TagId: environment.analytics.key, uri: environment.analytics.url }));
  providers.push(provideGoogleAnalyticsRouter());
}

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    ImgFallbackDirective,
    HeaderOldComponent,
    HeaderComponent,
    HeaderMinimalComponent,
    FooterComponent,
    HomeComponent,
    HowItWorksComponent,
    PartnerComponent,
    PricingPlanComponent,
    ClientsComponent,
    FreeTrialComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    AboutComponent,
    PricingComponent,
    TeamComponent,
    FeaturesComponent,
    FeaturesOneComponent,
    FeaturesTwoComponent,
    AuthComponent,
    SigninComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ProviderSigninComponent,
    ProviderCallbackComponent,
    TippingPageComponent,
    EditTippingPageComponent,
    TotpEnableComponent,
    TotpVerifyComponent,
    FaqComponent,
    ErrorComponent,
    BlogComponent,
    BlogGridComponent,
    BlogSidebarComponent,
    BlogRevolutionComponent,
    BlogOverlayObsComponent,
    BlogRewardsComponent,
    BlogTokenGatingComponent,
    ContactComponent,
    TermsConditionsComponent,
    HomeFourComponent,
    HomeFiveComponent,
    HomeSixComponent,
    HomeSevenComponent,
    HomeEightComponent,
    ServicesOneComponent,
    ServicesTwoComponent,
    ServicesThreeComponent,
    ServicesDetailsComponent,
    HomeNineComponent,
    HomeTenComponent,
    HomeElevenComponent,
    UserProfileComponent,
    WidgetsComponent,
    OverlaysComponent,
    WidgetTippingPageComponent,
    TippingContentComponent,
    NotificationsOverlayComponent,
    RewardsComponent,
    FormatAmountPipe,
    TransactionToastComponent,
    AdminMailComponent,
    AdminSupportComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SizeDetectorComponent,
    AgGridModule,
    NgbTooltipModule,
    NgbNavModule,
    NgxConfettiModule,
    CarouselModule,
    ImageCropperModule,
    NgOtpInputModule,
    QrCodeModule,
    ToastNoAnimationModule.forRoot(),
    NgcCookieConsentModule.forRoot(cookieConfig),
    ClipboardModule,
    WalletInputComponent,
    ...imports], providers: [
      FormatAmountPipe,
      UrlService,
      SeoService,
      AuthService,
      { provide: HTTP_INTERCEPTORS, useClass: LatencyInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
      {
        provide: APP_INITIALIZER,
        useFactory: (auth: AuthService) => {
          console.log(`[APP_INIT] Loading token from storage..`);
          auth.loadTokens();
          const expired = auth.isAccessTokenExpired();
          console.log(`[AuthGuard] access token expired: ${expired}`);
          if (expired) {
            if (!auth.isRefreshTokenExpired()) {
              console.log(`[AuthGuard] access token refreshing...`);
              return () => auth.refreshAccessToken()
                .pipe(map(() => true), catchError(err => {
                  auth.logoutUser();
                  return of(false);
                }));
            } else {
              console.log('[AuthGuard] logout user');
              return () => {
                auth.logoutUser();
                return of(false);
              }
            }
          }
          return () => of(true);
        },
        deps: [AuthService],
        multi: true,
      },
      ...providers,
      provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule { }
