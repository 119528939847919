import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  forwardRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  SelectControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ControlValueAccessor,
} from '@angular/forms';
import { NgForOf, NgIf } from '@angular/common';
import { WalletService } from '../../../shared';
import { noop, Subscription } from 'rxjs';

@Component({
  selector: 'ct-wallet-input',
  standalone: true,
  templateUrl: 'wallet-input.component.html',
  styleUrls: ['wallet-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WalletInputComponent),
      multi: true,
    },
  ],
  imports: [FormsModule, ReactiveFormsModule, NgForOf, FormsModule, NgIf],
})
export class WalletInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  public readonly walletService: WalletService = inject(WalletService);
  private walletSubscription?: Subscription;

  private readonly cd: ChangeDetectorRef = inject(ChangeDetectorRef);

  public wallets: string[] = [];
  @Input() label: string | null = null;
  @Input({ required: true }) formControlName = '';

  value: string | null = null;
  disabled = false;

  onChanged = (value: string | null) => noop;
  onTouched = () => noop;

  ngOnInit(): void {
    this.walletSubscription = this.walletService.walletData$.subscribe(walletData => {
      if (walletData) {
        if (!this.wallets.includes(walletData)) {
          this.wallets.push(walletData);
        }
      }
      const { addresses } = this.walletService.getAccount();
      if (addresses && addresses.length > 0) {
        addresses.forEach(address => {
          if (!this.wallets.includes(address)) {
            this.wallets.push(address);
          }
        });
      }
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    if (this.walletSubscription) {
      this.walletSubscription.unsubscribe();
    }
  }

  connectWallet() {
    this.walletService.connect();
  }

  selectWallet(event: Event) {
    this.onChanged(this.value);
    this.onTouched();
  }

  writeValue(value: string): void {
    if (value) {
      if (!this.wallets.includes(value)) {
        this.wallets.push(value);
      }
    }
    this.value = value;
    this.cd.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
