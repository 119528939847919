import {Component, OnDestroy, OnInit, inject} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '@ct/client/data-access';
import {BehaviorSubject, Observable, Subscription, filter, finalize, fromEvent, take} from 'rxjs';
import { UrlService } from 'apps/client/src/app/shared/services/url.service';
import {environment} from '@ct/shared/util-env';
import { AuthProviderEnum, IAccessTokenPayload } from '@ct/shared/domain';
import { WalletService } from 'apps/client/src/app/shared/services/wallet.service';
import { ProviderMessageEvent } from 'apps/client/src/app/shared';
import { TOKEN_STORAGE_KEY } from '@ct/client/util';

type LoginFormType = {
  email: FormControl<string>;
  password: FormControl<string>;
};
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit, OnDestroy {

  private readonly authService = inject(AuthService);
  public signinPending = false;
  private router = inject(Router);
  private previousUrlSubscription?: Subscription;
  private routeSubscription?: Subscription;
  private userSubscription?: Subscription;
  private previousUrl = '/';
  private windowRef: Window | null = null;
  public googleSigninPending = false;
  private storageSubscription?: Subscription;

  constructor(private Activatedroute: ActivatedRoute, private urlService: UrlService, private walletService: WalletService) { 
    
  }

  ngOnDestroy(): void {
    if(this.storageSubscription) {
      this.storageSubscription.unsubscribe();
    }
    if(this.previousUrlSubscription) {
      this.previousUrlSubscription.unsubscribe();
    }
    if(this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if(this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    if(this.authService.isLoggedIn) {
      if(this.previousUrl !== '/') {
        this.router.navigate([this.previousUrl]);
      } else {
        this.router.navigate(['/user/profile']);
      }
    }
    // Or subscribe to it here
    this.previousUrlSubscription = this.urlService.previousUrl$.subscribe((previousUrl: string) => {
      this.previousUrl = previousUrl;
    });
    this.userSubscription = this.authService.userData$.subscribe((user: IAccessTokenPayload | null) => {
      if(user != null) {
        if(this.previousUrl !== '/') {
          this.router.navigate([this.previousUrl]);
        } else {
          this.router.navigate(['/user/profile']);
        }
      }
    });
    this.routeSubscription = this.Activatedroute.queryParamMap.subscribe((params) => {
      const emailParam = params.get('email');
      if(emailParam) {
        this.fEmail.setValue(emailParam);
      }
    });
    
  }

  loginForm = new FormGroup<LoginFormType>({
    email: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.email],
    }),
    password: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  errorMessage$ = new BehaviorSubject<string | null>(null);

  get fEmail(): FormControl {
    return this.loginForm.controls.email as FormControl;
  }

  get fPassword(): FormControl {
    return this.loginForm.controls.password as FormControl;
  }

  login() {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }
    if (this.loginForm.valid && this.loginForm.dirty) {
      this.signinPending = true;
      this.errorMessage$.next(null);
      const { email, password } = this.loginForm.getRawValue();
      this.authService
        .loginUser({ email, password })
        .pipe(
          take(1),
          finalize(() => {
            this.signinPending = false;
          }),
        )
        .subscribe({
          next: () => {
            console.log(`User authenticated, redirecting to previous URL ...`, this.previousUrl);
            
          },
          error: (err) => {
            console.log(err);
            if (err instanceof Error && err.message) {
              this.errorMessage$.next(err.message);
            } else {
              this.errorMessage$.next(
                `Unknown error occurred while logging in!`
              );
            }
            console.error(err);
          },
        });
    }
  }

  loginWithGoogle() {
    this.loginForm.reset();
    this.errorMessage$.next(null);
    this.googleSigninPending = true;
    const provider: string = AuthProviderEnum.google;
    const authProviderURL:string = environment.authProviders[provider];
    if(authProviderURL) {
      this.windowRef = window.open(environment.apiUri + authProviderURL, "CoinTipsGoogleAuthentication", "width=550,height=700,top=200,left=500,toolbar=0,status=0,popup=yes");
      if(!this.windowRef) {
        this.errorMessage$.next(
          `Error occurred while trying to open Google Authentication Window, try again.`
        );
        return;
      }
      this.storageSubscription = fromEvent<StorageEvent>(window, 'storage')
      .pipe(
        filter(event => event.key === TOKEN_STORAGE_KEY),
        filter(event => event.newValue !== null),
      )
      .subscribe((_) => {
          window.location.reload();
      });
    }
  }

  loginWithWeb3() {
    this.loginForm.reset();
    this.walletService.connect();
  }
}
