import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IReward } from '@ct/shared/domain';
import { IEnvironment, environment } from '@ct/shared/util-env';
import { OwlOptions } from 'ngx-owl-carousel-o';

export interface RewardSelectedEvent {
  reward: IReward;
}

@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.scss']
})
export class RewardsComponent implements OnInit {
  public environment: IEnvironment = environment;

  owlOptions: OwlOptions = {
    loop: false,
    nav: true,
    dots: true,
    autoplayHoverPause: false,
    autoplay: false,
    margin: 30,
    navText: [
      "<i class='bx bx-chevron-left'></i>",
      "<i class='bx bx-chevron-right'></i>",
    ],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    }
  }

  @Input()
  public rewards: IReward[] = [];

  @Input()
  public includedRewardId?: number | null;

  @Output()
  public rewardSelected: EventEmitter<RewardSelectedEvent> = new EventEmitter<RewardSelectedEvent>();

  constructor() { }

  ngOnInit() {
  }

  selectReward(reward: IReward) {
    this.includedRewardId = reward.id;
    this.rewardSelected.emit({ reward: reward });
  }

}
