import { IToken } from '../models';
import { NetworkEnum } from '../types';
import * as _ from 'lodash';

export const ZeroAddress = '0x0000000000000000000000000000000000000000';

export const TOKENS_BY_NETWORK: { [key in NetworkEnum]: IToken[] } = {
  1: [
    {
      address: ZeroAddress,
      symbol: 'ETH',
      decimals: 18,
      network: NetworkEnum.Mainnet,
    },
    {
      address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      symbol: 'USDC',
      decimals: 6,
      network: NetworkEnum.Mainnet,
    },
    {
      address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      symbol: 'WETH',
      decimals: 18,
      network: NetworkEnum.Mainnet,
    },
    {
      symbol: 'WBTC',
      decimals: 8,
      address: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
      network: NetworkEnum.Mainnet,
    }
  ],
  137: [
    {
      address: ZeroAddress,
      symbol: 'MATIC',
      decimals: 18,
      network: NetworkEnum.Polygon,
    },
    {
      address: '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
      symbol: 'USDC',
      decimals: 6,
      network: NetworkEnum.Polygon,
    },
    {
      address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
      symbol: 'WETH',
      decimals: 18,
      network: NetworkEnum.Polygon,
    },
    {
      symbol: 'WBTC',
      decimals: 8,
      address: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
      network: NetworkEnum.Polygon,
    }
    
  ],
  31_337: [
    {
      address: ZeroAddress,
      symbol: 'ETH',
      decimals: 18,
      network: NetworkEnum.Hardhat,
    },
    {
      address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      symbol: 'USDC',
      decimals: 6,
      network: NetworkEnum.Hardhat,
    },
    {
      address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      symbol: 'WETH',
      decimals: 18,
      network: NetworkEnum.Hardhat,
    },
    {
      symbol: 'WBTC',
      decimals: 8,
      address: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
      network: NetworkEnum.Hardhat,
    }
  ],
  11_155_111: [
    {
      address: ZeroAddress,
      symbol: 'ETH',
      decimals: 18,
      network: NetworkEnum.Sepolia,
    },
    {
      address: '0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238',
      symbol: 'USDC',
      decimals: 6,
      network: NetworkEnum.Sepolia,
    },
    {
      address: '0x7b79995e5f793A07Bc00c21412e50Ecae098E7f9',
      symbol: 'WETH',
      decimals: 18,
      network: NetworkEnum.Sepolia,
    },
    {
      symbol: 'WBTC',
      decimals: 8,
      address: '0x92f3B59a79bFf5dc60c0d59eA13a44D082B2bdFC',
      network: NetworkEnum.Sepolia,
    }
  ],
};

export const TOKENS_BY_ADDRESS: { [key in NetworkEnum]: {[key: string] : IToken}} = _.mapValues(TOKENS_BY_NETWORK, (tokens: IToken[]) => _.keyBy(tokens, 'address'));