import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import {
  IToken,
  INetwork,
  IPage,
  IReward,
  ITipRequest,
  ITipResponse,
  ITip,
  TipStatusEnum,
  ICreateTip,
  IUpdateTip,
  IQuote,
  NETWORKS_BY_ENV,
  TOKENS_BY_NETWORK,
  TOKENS_BY_ADDRESS,
  networkByNumber,
  ITransaction,
  ZeroAddress,
  NETWORKS_BY_ENUM,
  IAccessTokenPayload,
} from '@ct/shared/domain';
import {
  BehaviorSubject,
  Subscription,
  debounceTime,
  delay,
  distinctUntilChanged,
  finalize,
  first,
  from,
  of,
  switchMap,
  timer,
} from 'rxjs';
import { AuthService, TipService } from '@ct/client/data-access';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WalletService } from '../../../shared';
import * as _ from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { waitForTransactionReceipt } from '@wagmi/core';
import { formatUnits, maxUint256 } from 'viem';
import { ConfettiOptions } from '@tsparticles/confetti';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { MarketDataManagerService } from '../../../shared';
import { TransactionToastComponent } from '@ct/client/ui-components';
import { environment } from '@ct/shared/util-env';
import { Dictionary } from 'lodash';
import { round } from '@ct/client/util';

type TipFormType = {
  name: FormControl<string>;
  token: FormControl<string>;
  amount: FormControl<number>;
  network: FormControl<number>;
  message: FormControl<string | null>;
  excludeRewards: FormControl<boolean>;
};

export const TIP_SUGGESTIONS = [10, 50, 100, 200, 500];

export interface TipAmountChangedEvent {
  token: IToken;
  value: number;
}

@Component({
  selector: 'app-tipping-content',
  templateUrl: './tipping-content.component.html',
  styleUrls: ['./tipping-content.component.scss'],
})
export class TippingContentComponent implements OnInit, OnDestroy {
  @Input()
  public page?: IPage | null;

  @Input()
  public includedReward?: IReward | null;

  @Output()
  tipChanged: EventEmitter<TipAmountChangedEvent> = new EventEmitter<TipAmountChangedEvent>();

  public readonly authService = inject(AuthService);
  public tips?: ITip[] = [];

  private userId?: string | null;
  public lastQuotes?: { [key: string]: IQuote } | null;
  public tipPending = false;
  public approvePending = false;
  public needApprove = false;
  public needNetworkSwitch = false;
  public needDeposit = false;
  public currentNetwork: INetwork;
  public currentToken: IToken;
  public currentTokenBalance?: number;
  public currentTokenAllowance?: number;
  public tipAmountUSD?: number | null;
  public fireConfetti = false;
  private amountSubscription?: Subscription;
  private tokenSubscription?: Subscription;
  private networkSubscription?: Subscription;
  private userSubscription?: Subscription;
  private walletSubscription?: Subscription;
  private walletNetworkSubscription?: Subscription;
  private walletNetworkId: number | null = null;
  private marketDataSubscription?: Subscription;

  public confettiOptions: ConfettiOptions = {
    particleCount: 200,
  };

  public availableNetworkList = NETWORKS_BY_ENV[environment.type];
  public availableTokenList;
  public availableTokenMap: Dictionary<IToken>;
  public tipSuggestions = TIP_SUGGESTIONS;
  public tipStatusEnum: typeof TipStatusEnum = TipStatusEnum;

  constructor(
    public walletService: WalletService,
    private tipService: TipService,
    private marketDataManagerService: MarketDataManagerService,
    private toastrService: ToastrService,
  ) {
    this.currentNetwork = this.availableNetworkList[0];
    this.availableTokenList = TOKENS_BY_NETWORK[this.currentNetwork.id];
    this.availableTokenMap = TOKENS_BY_ADDRESS[this.currentNetwork.id];
    this.currentToken = this.availableTokenMap[ZeroAddress];
  }

  tipForm = new FormGroup<TipFormType>({
    name: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(50)],
    }),
    token: new FormControl<string>(
      { value: ZeroAddress, disabled: false },
      {
        nonNullable: true,
        validators: [Validators.required],
      },
    ),
    network: new FormControl<number>(
      { value: this.availableNetworkList[0].id, disabled: true },
      {
        nonNullable: true,
        validators: [Validators.required],
      },
    ),
    amount: new FormControl<number>(0, {
      nonNullable: true,
      validators: [Validators.required, Validators.min(0.000001)],
    }),
    message: new FormControl<string>('', {
      nonNullable: false,
      validators: [Validators.maxLength(255)],
    }),
    excludeRewards: new FormControl<boolean>(false, {
      nonNullable: true,
    }),
  });

  errorMessage$ = new BehaviorSubject<string | null>(null);
  walletErrorMessage$?: BehaviorSubject<string | null>;

  get fName(): FormControl {
    return this.tipForm.controls.name as FormControl;
  }

  get fAmount(): FormControl {
    return this.tipForm.controls.amount as FormControl;
  }

  get fMessage(): FormControl {
    return this.tipForm.controls.message as FormControl;
  }

  get fToken(): FormControl {
    return this.tipForm.controls.token as FormControl;
  }

  get fExcludeRewards(): FormControl {
    return this.tipForm.controls.excludeRewards as FormControl;
  }

  get fNetwork(): FormControl {
    return this.tipForm.controls.network as FormControl;
  }

  ngOnDestroy(): void {
    if (this.amountSubscription) {
      this.amountSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.marketDataSubscription) {
      this.marketDataSubscription.unsubscribe();
    }
    if (this.walletSubscription) {
      this.walletSubscription.unsubscribe();
    }
    if (this.walletNetworkSubscription) {
      this.walletNetworkSubscription.unsubscribe();
    }
    if (this.networkSubscription) {
      this.networkSubscription.unsubscribe();
    }
    if (this.tokenSubscription) {
      this.tokenSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.userSubscription = this.authService.userData$.subscribe((userData: IAccessTokenPayload | null) => {
      if (userData) {
        this.userId = userData?.sub;
        if (!this.fName.value) {
          this.fName.setValue(userData?.pseudo);
        }
      } else {
        this.walletService.disconnectWallet();
      }
    });
    this.marketDataSubscription = timer(0, 300000)
      .pipe(switchMap(() => this.marketDataManagerService.getLastQuotes()))
      .subscribe((lastQuotes) => {
        if (lastQuotes != null) {
          this.lastQuotes = lastQuotes;
        }
      });
    this.walletSubscription = this.walletService.walletData$.subscribe(async (address: string | null) => {
      await this.onUserWalletChange(address);
    });
    this.walletNetworkSubscription = this.walletService.networkData$.subscribe(async (chainId: number | null) => {
      await this.onUserNetworkChange(chainId);
    });
    this.amountSubscription = this.fAmount.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((amount) => this.onAmountChange(amount));

    this.tokenSubscription = this.fToken.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((token) => this.onTokenChange(token));

    this.networkSubscription = this.fNetwork.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((chainId) => this.onNetworkChange(chainId));
  }

  onPageChange(page: IPage) { }

  onIncludedRewardsChange(rewards: IReward[]) { }

  fillTipAmount(value: string, token: string, forceToken = false) {
    if (this.currentToken && this.currentToken.address !== token) {
      if (forceToken) {
        // this.tipForm.patchValue({ token: token, amount: parseFloat(value) });
        const tokenDetails = this.availableTokenMap[token];
        if (tokenDetails && this.lastQuotes && this.lastQuotes[tokenDetails.symbol]) {
          this.tipAmountUSD = parseFloat(value) * this.lastQuotes[tokenDetails.symbol].value;
        }
        this.fToken.setValue(token);
      } else {
        const tokenDetails = this.availableTokenMap[token];
        if (tokenDetails && this.lastQuotes && this.lastQuotes[tokenDetails.symbol]) {
          const tipAmountUSD = parseFloat(value) * this.lastQuotes[tokenDetails.symbol].value;
          this.fillTipAmountUSD(tipAmountUSD);
        }
      }

    } else {
      this.fAmount.setValue(parseFloat(value));
    }
  }

  fillTipAmountUSD(amountUSD: number) {
    if (this.currentToken && this.lastQuotes && this.lastQuotes[this.currentToken.symbol]) {
      const tipAmount = amountUSD / this.lastQuotes[this.currentToken.symbol].value;
      this.fAmount.setValue(round(tipAmount, 6));
    }
  }

  triggerConfetti() {
    this.fireConfetti = true;
    of(false)
      .pipe(delay(200), first())
      .subscribe((value) => (this.fireConfetti = false));
  }

  async onUserWalletChange(address: string | null) {
    await this.updateBalance();
    await this.updateApproval();
  }

  async onUserNetworkChange(chainId: number | null) {
    this.walletNetworkId = chainId;
    this.needNetworkSwitch = this.walletNetworkId != null && this.currentNetwork.id !== this.walletNetworkId;
    await this.updateBalance();
    await this.updateApproval();
  }

  async onNetworkChange(chainId: number) {
    console.log('onNetworkChange', chainId);
    this.currentNetwork = NETWORKS_BY_ENUM[networkByNumber(chainId)];
    this.availableTokenList = TOKENS_BY_NETWORK[this.currentNetwork.id];
    this.availableTokenMap = TOKENS_BY_ADDRESS[this.currentNetwork.id];
    this.needNetworkSwitch = this.walletNetworkId != null && this.currentNetwork.id !== this.walletNetworkId;

    await this.onTokenChange(this.availableTokenMap[ZeroAddress].address);//TODO search token in new network with same symbol
  }

  async onTokenChange(token: string) {
    this.currentToken = this.availableTokenMap[token];
    if (this.tipAmountUSD && this.lastQuotes && this.lastQuotes[this.currentToken.symbol]) {
      const tipAmount = this.tipAmountUSD / this.lastQuotes[this.currentToken.symbol].value;
      this.fAmount.setValue(round(tipAmount, 6));
    }

    await this.updateBalance();
    await this.updateApproval();
  }

  async updateBalance() {
    if (this.walletService.account) {
      const currentTokenBalance = await this.walletService.getBalance(this.walletService.account, this.currentToken.address, this.currentNetwork.id);
      this.currentTokenBalance = parseFloat(formatUnits(currentTokenBalance, this.currentToken.decimals));
      this.checkBalance(this.currentTokenBalance, this.fAmount.value);
    } else {
      this.currentTokenBalance = undefined;
    }
  }

  async updateApproval() {
    if (this.currentToken.address != ZeroAddress) {
      if (
        !(
          environment.contracts &&
          environment.contracts[this.currentNetwork.id] &&
          environment.contracts[this.currentNetwork.id]!.orchestrator
        )
      ) {
        this.errorMessage$.next('The contract is not deployed on this network.');
        this.needApprove = true;
      } else if (this.walletService.account) {
        const allowance = await this.walletService.getAllowance(this.walletService.account, environment.contracts[this.currentNetwork.id]!.orchestrator, this.currentToken.address, this.currentNetwork.id);
        this.currentTokenAllowance = parseFloat(formatUnits(allowance, this.currentToken.decimals));
        await this.checkAllowance(this.currentTokenAllowance, this.fAmount.value);
      } else {
        this.needApprove = true;
      }
    } else {
      this.needApprove = false;
    }
  }

  async onAmountChange(tipAmount: number) {
    if (tipAmount && this.lastQuotes && this.lastQuotes[this.currentToken.symbol]) {
      this.tipAmountUSD = tipAmount * this.lastQuotes[this.currentToken.symbol].value;
    } else {
      this.tipAmountUSD = null;
    }
    if (this.currentTokenAllowance) {
      this.checkAllowance(this.currentTokenAllowance, this.fAmount.value);
    } else {
      await this.updateApproval();
    }
    if (this.currentTokenBalance) {
      this.checkBalance(this.currentTokenBalance, this.fAmount.value);
    } else {
      await this.updateBalance();
    }

    this.tipChanged.emit({
      value: tipAmount,
      token: this.currentToken,
    });
  }

  connectWallet() {
    this.walletService.connect();
  }

  openAccount() {
    this.walletService.viewAccount();
  }

  openOnRamp() {
    this.walletService.openOnRamp();
  }

  switchNetwork() {
    this.walletService.switchNetwork(this.currentNetwork.id);
  }

  async tip() {
    console.log('form', this.tipForm);
    if (this.tipForm.invalid) {
      this.tipForm.markAllAsTouched();
      return;
    }
    if (this.tipForm.valid) {
      this.errorMessage$.next(null);

      const { name, token, amount, network, message, excludeRewards } = this.tipForm.getRawValue();
      const account = this.walletService.account;
      if (!account) {
        this.errorMessage$.next('Connect your wallet first !');
        return;
      }

      const selectedNetwork = networkByNumber(network);
      if (
        !(
          environment.contracts &&
          environment.contracts[selectedNetwork] &&
          environment.contracts[selectedNetwork]!.orchestrator
        )
      ) {
        this.errorMessage$.next('The contract is not deployed on the selected network.');
        return;
      }
      const orchestratorContract = environment.contracts[selectedNetwork]!.orchestrator;
      this.tipPending = true;
      if (token != ZeroAddress) {
        await this.updateApproval();
        if (this.needApprove) {
          await this.increaseAllowanceIfNeeded(selectedNetwork, orchestratorContract, account, this.currentToken, amount);
          this.tipPending = false;
          return;
        }
      }
      console.log('After allowance', this.needApprove);

      const tipRequest: ITipRequest = {
        pageId: this.page!.id,
        userId: this.userId!,
        amount: amount.toString(),
        token: token,
        chainId: network,
        tipperWallet: account,
        skipRewards: excludeRewards,
        rewardIds: excludeRewards ? [] : this.includedReward ? [this.includedReward.id!] : [],
      };
      console.log(tipRequest);
      this.tipService
        .requestTip(tipRequest)
        .pipe
        // take(1)
        ()
        .subscribe({
          next: async (tipResponse: ITipResponse) => {
            const txHash = await this.walletService.tip(tipResponse, this.errorMessage$);
            if (txHash) {
              console.info(`Transaction ${txHash}`);
              const tip: ICreateTip = {
                pageId: tipResponse.pageId,
                userId: tipResponse.userId,
                pseudo: name,
                message: message ? message : null,
                txHash: txHash,
                wallet: tipResponse.tipperWallet,
                amount: tipResponse.amount,
                token: tipResponse.token,
                chainId: tipResponse.chainId,
                status: TipStatusEnum.created,
                rewardIds: tipResponse.rewardIds,
              };
              const transaction = {
                chainId: network,
                msgSender: account,
                txHash: txHash,
              } as ITransaction;
              const txToast: ActiveToast<any> = this.toastrService.info(
                'Waiting for blockchain confirmation.',
                'Tip submitted',
                {
                  disableTimeOut: true,
                  tapToDismiss: false,
                  closeButton: true,
                  toastComponent: TransactionToastComponent,
                  payload: transaction,
                },
              );
              this.tipService
                .createTip(tip)
                .pipe(
                  first(),
                  finalize(() => {
                    this.tipPending = false;
                  })
                )
                .subscribe((tip) => {
                  this.tips?.push(tip);
                  from(waitForTransactionReceipt(this.walletService.config, { chainId: network, hash: txHash as `0x${string}` }))
                    .pipe(first())
                    .subscribe((txResult) => {
                      const updatedTip: IUpdateTip = {
                        txHash: txHash,
                        chainId: network
                      };

                      txToast.toastRef.close();
                      if (txResult.status == 'success') {
                        this.triggerConfetti();
                        this.toastrService.success('Tip successfully executed', 'Tip');
                        updatedTip.status = TipStatusEnum.executed;
                        updatedTip.executedAt = new Date();
                      } else {
                        updatedTip.status = TipStatusEnum.failed;
                        this.toastrService.error('Error occurs on-chain', 'Tip');
                      }
                      this.tipService
                        .updateTip(tip.id, updatedTip)
                        .pipe(first())
                        .subscribe((tip) => {
                          console.log(tip);
                        });
                    });
                });
            } else {
              this.tipPending = false;
            }
          },
          error: (err) => {
            if (err instanceof HttpErrorResponse) {
              this.errorMessage$.next(err.error.message);
            } else if (err.message) {
              this.errorMessage$.next(err.message);
            } else {
              this.errorMessage$.next(`Unknown error occurred while preparing the tip!`);
            }
            this.tipPending = false;
            console.error(err);
          },
        });
    }
  }

  async checkAllowance(tokenAllowance: number, tipAmount: number) {
    console.log('Allowance', tokenAllowance);
    this.needApprove = tipAmount > tokenAllowance;
  }

  async checkBalance(tokenBalance: number, tipAmount: number) {
    console.log('Balance', tokenBalance);
    this.needDeposit = tipAmount > tokenBalance;
  }

  async increaseAllowanceIfNeeded(
    network: number,
    orchestratorContract: string,
    account: string,
    token: IToken,
    amount: number
  ) {

    const txHash = await this.walletService.approve(
      account,
      orchestratorContract,
      token.address,
      maxUint256,
      network,
      this.errorMessage$,
    );
    if (txHash) {
      console.info(`Transaction ${txHash}`);
      const transaction = {
        chainId: network,
        msgSender: account,
        txHash: txHash,
      } as ITransaction;
      const txToast: ActiveToast<any> = this.toastrService.info(
        'Waiting for blockchain confirmation.',
        'Approval submitted',
        {
          disableTimeOut: true,
          tapToDismiss: false,
          closeButton: true,
          toastComponent: TransactionToastComponent,
          payload: transaction,
        },
      );
      from(waitForTransactionReceipt(this.walletService.config, { hash: txHash as `0x${string}` }))
        .pipe(first())
        .subscribe(async (txResult) => {
          txToast.toastRef.close();
          if (txResult.status == 'success') {
            this.toastrService.success('Approval executed', 'CoinTips');
            await this.updateApproval();
          } else {
            this.toastrService.error('Approval error occurs on-chain', 'CoinTips');
          }
        });
    }
    return;
  }

}
