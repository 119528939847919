<section class="tp-widget container">
  <div class="shape1"><img src="assets/img/shape/1.png" alt="image" /></div>
  <div class="shape2"><img src="assets/img/shape/2.png" alt="image" /></div>
  <div class="shape3"><img src="assets/img/shape/3.png" alt="image" /></div>
  <div class="shape4"><img src="assets/img/shape/4.png" alt="image" /></div>
  <div class="shape5"><img src="assets/img/shape/5.png" alt="image" /></div>
  <div class="shape6"><img src="assets/img/shape/6.png" alt="image" /></div>
  <ng-container
    *ngIf="{
      page: page$ | async,
      rewards: rewards$ | async,
      medias: medias$ | async
    } as data"
  >
    <div class="tree">
      <div class="header">
        <ng-container *ngIf="data.page; else headerLoading">
          <img src="assets/img/pages/default-profile.png" class="profile-pic" />
          <h2 class="page-name">{{ data.page.title }}</h2>
        </ng-container>
        <ng-template #headerLoading>
          <img src="assets/img/pages/default-profile.png" class="profile-pic" />
          <h2 class="page-name">Loading details ...</h2>
        </ng-template>

        <ng-container *ngIf="data.medias">
          <div class="contact-info">
            <div class="contact-info-content">
              <ul class="social">
                <ng-template ngFor let-media [ngForOf]="data.medias">
                  <li>
                    <a
                      [href]="mediaClasses[media.type].baseURL + media.value"
                      target="_blank"
                      ><i
                        class="bx"
                        [ngClass]="mediaClasses[media.type].iconClass"
                      ></i
                    ></a>
                  </li>
                </ng-template>
              </ul>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="tip">
        <fieldset class="border p-2">
          <legend class="float-none w-auto p-2 text-start">
            Your Tip Details
          </legend>
          <app-tipping-content [page]="data.page" [includedReward]="includedReward" (tipChanged)="tipChanged($event)"></app-tipping-content>
        </fieldset>
      </div>
      <div class="rewards" *ngIf="data.rewards && data.rewards.length > 0">
        <fieldset class="border p-2">
          <legend class="float-none w-auto p-2 text-start">
            Available Digital Community Passes
          </legend>
          <app-rewards [rewards]="data.rewards!" [includedRewardId]="includedRewardId" (rewardSelected)="rewardSelected($event)"></app-rewards>
        </fieldset>
      </div>
      <div class="shape20">
        <img src="assets/img/shape/19.png" alt="image" />
      </div>
      <div class="shape21">
        <img src="assets/img/shape/20.png" alt="image" />
      </div>
      <div class="shape19">
        <img src="assets/img/shape/18.png" alt="image" />
      </div>
      <div class="shape22">
        <img src="assets/img/shape/21.png" alt="image" />
      </div>
      <div class="shape23">
        <img src="assets/img/shape/22.svg" alt="image" />
      </div>
      <div class="shape24">
        <img src="assets/img/shape/23.png" alt="image" />
      </div>
      <div class="footer">
        <a href="/">CoinTips</a> &copy; 2023 - All Rights Reserved.
      </div>
    </div>
  </ng-container>
</section>
