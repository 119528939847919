export enum NetworkEnum {
  Mainnet = 1,
  Polygon = 137,
  Hardhat = 31_337,
  Sepolia = 11_155_111,
}

export function networkByNumber(network: number): NetworkEnum {
  switch (network) {
    case 137:
      return NetworkEnum.Polygon;
    case 31_337:
      return NetworkEnum.Hardhat;
    case 11_155_111:
      return NetworkEnum.Sepolia;
    case 1:
    default:
      return NetworkEnum.Mainnet;
  }
}
