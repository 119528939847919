import { INetwork } from "../models";
import { EnvironmentEnum, NetworkEnum } from "../types";

export const NETWORKS_BY_ENUM: { [key in NetworkEnum]: INetwork} = {
  1: {
    name: 'Ethereum',
    id: NetworkEnum.Mainnet,
  },
  137: {
    name: 'Polygon',
    id: NetworkEnum.Polygon,
  },
  31_337: {
    name: 'Hardhat',
    id: NetworkEnum.Hardhat,
  },
  11_155_111: {
    name: 'Sepolia',
    id: NetworkEnum.Sepolia,
  },
};

export const NETWORKS_BY_ENV: { [key in EnvironmentEnum]: INetwork[] } = {
  development: [
    NETWORKS_BY_ENUM[NetworkEnum.Hardhat]
  ],
  production: [
    NETWORKS_BY_ENUM[NetworkEnum.Mainnet]
  ],
  staging: [
    NETWORKS_BY_ENUM[NetworkEnum.Sepolia]
  ],
};

export const NETWORK_EXPLORER: { [key in NetworkEnum]: {baseURL: string, txURI: string} } = {
  1: {
    baseURL: 'https://etherscan.io',
    txURI: 'tx'
  },
  137: {
    baseURL:'https://polygonscan.com',
    txURI: 'tx'
  },
  31_337: {
    baseURL:'https://app.tryethernal.com/',
    txURI: 'transaction'
  },
  11_155_111: {
    baseURL:'https://sepolia.etherscan.io',
    txURI: 'tx'
  },
};
